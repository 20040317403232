<template>
  <div class="page-root">
    <answer-box
      @next="onNext"
      @back="onBack"
      :id="questions[number].id"
      :count="questions.length"
      :answer-text="questions[number].text"
      :choices="questions[number].choices"
      color="blue"
    />
  </div>
</template>

<script>
import rootStore from "@/store";
import { partBQuestions } from "../../data/adhd";
import AnswerBox from "@/components/adhd/AnswerBox";
export default {
  name: "AdhdSecondScreening",
  head: {
    title: {
      inner: "大人のADHDセルフチェック"
    }
  },
  data: function() {
    return {
      questions: partBQuestions(),
      number: 0
    };
  },
  components: { AnswerBox },
  beforeCreate: function() {
    rootStore.commit("adhd/resetBpartAnswer");
  },
  created() {
    this.sendGa("click", "adhd", "start-second-screening");
  },
  methods: {
    async onNext(idx) {
      this.$store.commit("adhd/changeScreeningBAnswerValue", {
        id: this.questions[this.number].id,
        value: idx
      });

      if (this.number === this.questions.length - 1) {
        await this.$router.push("/adhd/second_result");
      } else {
        this.number += 1;
      }
    },
    onBack() {
      this.number -= 1;
    }
  }
};
</script>

<style scope>
@import "../../assets/style/adhd.css";
</style>
